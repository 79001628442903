
<template>
<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5 d-flex align-items-center justify-content-center flex-column mx-auto">
            <div class="card">
                <div class="card-body p-4">
                    <div class="text-center mt-4">
                        <img width="40" :src="absoluteUrl('/images/sand-timer.gif')" />
                        <h4 class="mt-3 mb-1">Please wait!</h4>
                        <p class="text-muted mb-3">Authentication in progress ...</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
    computed:{
        fallbackUrl(){
           return this.absoluteUrl(`/login?intended_url=${location.origin}${this.$route.query.intended_url}`)
        }
    },
    async created(){
        if(!this.$route.query.token){
          location.replace(this.fallbackUrl);return
        }
        let token = JSON.parse(atob(this.$route.query.token));
        if(typeof token !== 'object' && token === null){
          location.replace(this.fallbackUrl);return
        }
        let formData = new FormData();
        formData.append('data', JSON.stringify(token));
        this.$http.post(this.absoluteUrl('/api/auto-login'), formData)
        .then((response) => {
            if(response.data.success){
                this.$store.commit("SET_AUTH_TOKEN", response.data.data)
                location = `${this.$route.query.intended_url}`;
            }else{
                location.replace(this.fallbackUrl)
            }
        })
    }
}

</script>
